import React, { useState, useMemo } from 'react';

import { Box, Container } from '@mui/material';

import { FooterContainer } from './footer';
import { LoginLayout } from './LoginLayout';
import { MenuBar } from './MenuBar';

const Layout = ({ children, contentSx, ...props }) => {
  const [showMenu, setShowMenu] = useState(true);
  const [newNotifications, setNewNotifications] = useState(false);

  const [isShowFooter, showFooter] = useState(false);

  const hideMenu = (value = false) => {
    setShowMenu(value);
  };

  const setUpdateNotifications = (value) => {
    setNewNotifications(value);
  };

  const Children = useMemo(
    () =>
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            hideMenu,
            setUpdateNotifications,
            showFooter,
            ...props,
          });
        }

        return child;
      }),
    [props.uri]
  );

  return (
    <LoginLayout
      contentProps={{
        noMenu: !showMenu,
        FullContent: () => (
          <>
            <Box
              id="LAYOUT"
              sx={{
                width: 1,
                height: 1,
                overflowY: 'auto',
                overflowX: 'hidden',
                ...contentSx,
              }}
            >
              <Container maxWidth="md" sx={{ height: 1 }}>
                {Children}
                {showMenu && (
                  <MenuBar
                    {...props}
                    setUpdateNotifications={setUpdateNotifications}
                    updateNotifications={newNotifications}
                  />
                )}
              </Container>
            </Box>
            {(showMenu || isShowFooter) && <FooterContainer />}
          </>
        ),
        sx: {
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    ></LoginLayout>
  );
};

export { Layout };
