import { format } from 'date-fns';

import { stripIndents } from 'common-tags';

import { formateHour, cepMask } from './masks';

import { getCurrency } from './index';

export const getMessageToSharing = (vaga) => {
  return stripIndents`
  🏥  *Hospital*: ${vaga.company.name}
  
  📍  *Endereço*: ${vaga.company.address}, ${vaga.company.number} - ${vaga.company.city}, ${
    vaga.company.state
  } - ${cepMask(vaga.company.cep)}
  
  🩺  *Especialidade*: ${vaga.especialidade.DS_ESPECIALIDADE.toLowerCase()}
  ㅤㅤ *Complexidade*: ${vaga.complexidade}
  ㅤㅤ *Contratação*: ${vaga.contratacao} 
  
  ║▌║ *Código da vaga*: ${vaga.code ?? ''}
  
  📅  *Data*: ${format(vaga.date.toDate(), 'dd/MM/yyyy')}
  ㅤㅤ *Entrada*: ${formateHour(vaga.hour)}
  ㅤㅤ *Duração*: ${vaga.duration} horas
  
  💰  *Valor*: ${vaga.aCombinar ? 'valor a combinar' : getCurrency(vaga.value)}
  ㅤㅤ *Data*: ${vaga.aVista ? 'À vista' : vaga.payDay?.toDate ? format(vaga.payDay.toDate(), 'dd') : ''}
  
  ㅤㅤ 
  *Baixe CEMED no seu smartphone* 📲👇
  
  *iOS*:
  https://apps.apple.com/br/app/cms-sa%C3%BAde/id6446066620
  
  *Android*:
  https://play.google.com/store/apps/details?id=com.cmss.app
  
  ㅤㅤ
  `;
};
