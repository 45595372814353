import React from 'react';

import { Helmet } from 'react-helmet';

/**
 * Componente para controlar configurações de viewport
 * Ajuda a prevenir problemas de rolagem em dispositivos móveis
 *
 * @returns {JSX.Element} Componente com meta tags de viewport
 */
export const ViewportMeta = () => (
  <Helmet>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
    />
  </Helmet>
);

export default ViewportMeta;
