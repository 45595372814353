import React from 'react';

import { Box } from '@mui/material';

import { Content } from './Content';
import { FullContainer } from './FullContainer';
import { TopBar } from './Topbar';
import ViewportMeta from './ViewportMeta';

function LoginLayout({ children, contentProps }) {
  return (
    <>
      <ViewportMeta />
      <FullContainer
        sx={{
          background: 'linear-gradient(197.56deg, #D9E1F2 0%, #FFFFFF 100%)',
          flexDirection: 'column',
        }}
      >
        <TopBar />
        <Box className="flex flex-col w-full h-full overflow-hidden">
          <Content noMenu {...contentProps}>
            {children}
          </Content>
        </Box>
      </FullContainer>
    </>
  );
}

export { LoginLayout };
