import React, { createContext, useState, useEffect, useContext } from 'react';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  deleteUser,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import { setDoc, doc, getFirestore, getDoc } from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.GATSBY_APIKEY,
  authDomain: process.env.GATSBY_AUTHDOMAIN,
  projectId: process.env.GATSBY_PROJECTID,
  storageBucket: process.env.GATSBY_STORAGEBUCKET,
  messagingSenderId: process.env.GATSBY_MESSAGINGSENDERID,
  appId: process.env.GATSBY_APPID,
  measurementId: process.env.GATSBY_MEASUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    // signOut(getAuth(app));
    onAuthStateChanged(getAuth(app), (userData) => {
      setUser(userData);
      setLoadingUser(false);
    });
  }, []);

  useEffect(() => {
    if (user && !userInfo) {
      updateUserInfo();
    }
  }, [user, userInfo]);

  const updateUserInfo = async () => {
    if (user) {
      const docRef = doc(db, 'users', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserInfo(docSnap.data());
      }
    }
  };

  const getUser = () => {
    const autapp = getAuth(app);

    return autapp.currentUser;
  };

  const excludeUser = async () => {
    try {
      const authapp = getAuth(app);
      await deleteUser(authapp.currentUser);
      return { success: true };
    } catch (err) {
      return { error: true };
    }
  };

  const checkEmail = async (email) => {
    try {
      const result = await fetchSignInMethodsForEmail(getAuth(app), email);

      return result;
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const register = async (data) => {
    try {
      const result = await createUserWithEmailAndPassword(
        getAuth(app),
        data.email.trim(),
        data.password.trim()
      );

      const copy = { ...data };
      const { avatar } = data;
      // delete copy.email;
      delete copy.password;
      delete copy.passwordConfirm;
      delete copy.avatar;

      let avatarUrl = null;

      if (avatar) {
        const avatarRef = ref(storage, `${result.user.uid}/avatar.png`);

        const snap = await uploadString(avatarRef, avatar, 'data_url');

        avatarUrl = await getDownloadURL(snap.ref);
      }

      await setDoc(doc(db, 'users', result.user.uid), {
        avatar: avatarUrl,
        roles: ['user'],
        ...copy,
      });

      await emailVerification(result.user);

      return { success: true };
    } catch (err) {
      return { error: true };
    }
  };

  const emailVerification = async (user) => {
    try {
      await sendEmailVerification(user);

      return { success: true };
    } catch (err) {
      return { error: err };
    }
  };

  const login = async ({ email, password }) => {
    try {
      await signInWithEmailAndPassword(getAuth(app), email, password);

      return { success: true };
    } catch (err) {
      return { error: true };
    }
  };

  const logout = () => {
    signOut(getAuth(app));
    setUser(null);
    setUserInfo(null);
  };

  const changePassword = async (newPass) => {
    try {
      await updatePassword(user, newPass);

      return { success: true };
    } catch (err) {
      console.log(err);

      return { error: true };
    }
  };

  const forgotPassword = async (email) => {
    try {
      await sendPasswordResetEmail(getAuth(app), email);

      return { success: true };
    } catch (err) {
      return { error: true, code: err.code };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userInfo,
        loadingUser,
        updateUserInfo,
        excludeUser,
        register,
        login,
        logout,
        changePassword,
        getUser,
        forgotPassword,
        checkEmail,
        emailVerification,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);

export { AuthProvider, app, db, storage };
